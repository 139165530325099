import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';


class HistoricalLoans extends Component {
    static displayName = HistoricalLoans.name;

    render() {
        return (
            <div>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/">Hem</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Tidigare Lån</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <h1>Tidigare lån</h1>
                    <p>Här skulle ni kunna se tidigare lån, men jag har inte varit förmögen att hitta äldre lån på någon användare...</p>
                </div>
            </div>
        );
    }
}

export default withCookies(HistoricalLoans);
