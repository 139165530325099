import React, { useState, useEffect } from 'react';
import { Card, CardGroup, CardHeader, CardBody, CardTitle, CardText, Row, Col, Button, Table, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheck, faDownload } from '@fortawesome/free-solid-svg-icons'

import RepaymentModal from './RepaymentModal';
import LoanFreeMonthModal from './LoanFreeMonthModal';

function CurrentLoan(props) {
    const [account, setAccount] = useState({});
    const [loanReminders, setLoanReminders] = useState([]);
    const [paymentPlan, setPaymentPlan] = useState([]);
    const [transactions, setTransactions] = useState([]);
    /*    const [loanRepayments, setLoanRepayments] = useState([]);*/
    const [isLoadingAccount, setIsLoadingAccount] = useState(true);
    const [isLoadingReminders, setIsLoadingReminders] = useState(true);
    const [isLoadingPaymentPlan, setIsLoadingPaymentPlan] = useState(true);
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal2IsOpen, setModal2IsOpen] = useState(false);
    const navigate = useNavigate();
    const { cookies } = props;

    const toggleRepaymentModal = () => {
        setModalIsOpen(!modalIsOpen);
    }

    const toggleRepaymentPostponementModal = () => {
        setModal2IsOpen(!modal2IsOpen);
    }

    useEffect(() => {
        const fetchData = async () => {

            const fetchReminders = async () => {
                try {
                    const response = await fetch('/goodcash/loanreminders', { credentials: "include" });
                    let result;
                    if (response.status !== 200) {
                        result = { error: response.statusText };
                        if (response.status === 401) {
                            navigate('/logout');
                        }
                        else {
                            console.log('Error fetching loan reminders:', result);
                            result = [];
                        }
                    }
                    else {
                        result = await response.json();
                    }
                    setLoanReminders(result);
                    setIsLoadingReminders(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    //setIsLoading(false);
                }
            };

            const fetchPaymentPlan = async () => {
                try {
                    const response = await fetch('/goodcash/paymentplan', { credentials: "include" });
                    let result;
                    if (response.status !== 200) {
                        result = { error: response.statusText }
                        if (response.status === 401) {
                            navigate('/logout');
                        }
                        else {
                            console.log('Error fetching payment plan:', result);
                            result = {};
                        }
                    }
                    else {
                        result = await response.json();
                    }
                    setPaymentPlan(result);
                    setIsLoadingPaymentPlan(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    //setIsLoading(false);
                }
            };

            const fetchTransactions = async () => {
                try {
                    const response = await fetch('/goodcash/transactions', { credentials: "include" });
                    let result;
                    if (response.status !== 200) {
                        result = { error: response.statusText };
                        if (response.status === 401) {
                            navigate('/logout');
                        }
                        else {
                            console.log('Error fetching tranactions:', result);
                            result = [];
                        }
                    }
                    else {
                        result = await response.json();
                    }
                    setTransactions(result);
                    setIsLoadingTransactions(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    //setIsLoading(false);
                }
            };

            try {
                const response = await fetch('/goodcash/account', { credentials: "include" });
                let result;
                if (response.status !== 200) {
                    result = { error: response.statusText };
                    if (response.status === 401) {
                        navigate('/logout');
                    }
                }
                else {
                    result = await response.json();
                }
                setAccount(result);
                setIsLoadingAccount(false);

                // Trigger fetching of the rest of the data.
                fetchReminders();
                fetchPaymentPlan();
                fetchTransactions();
            } catch (error) {
                console.error('Error fetching data:', error);
                //setIsLoading(false);
            }
        };

        fetchData();
    }, [navigate, ]);



    const translateState = (state) => {
        switch (state) {
            case "N":
                return "Normalt";
            case "C":
                return "Återbetalas";
            case "S":
                return "Lämnat till inkasso";
            default:
                return "Okänd";
        }
    }

    const downloadLoanReminder = async (attachments) => {
        try {
            var id = attachments.slice(0).pop().split('/').pop();

            const response = await fetch('/goodcash/loanreminders/download/' + id, { credentials: "include" });
            if (response.status === 200) {
                const blobData = await response.blob();
                // Create a Blob URL
                const blobUrl = URL.createObjectURL(blobData);

                // Create an anchor element to trigger the download
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'reminder_' + id + '.pdf';

                // Programmatically click the link to initiate the download
                downloadLink.click();

                // Clean up by revoking the Blob URL
                URL.revokeObjectURL(blobUrl);
            }
            else {
                if (response.status === 401) {
                    navigate('/logout');
                }
                console.error('Error fetching data:', response.statusText);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    return (
        <div>
            {isLoadingAccount || isLoadingReminders || isLoadingPaymentPlan || isLoadingTransactions ? (
                <div className="d-flex flex-column align-items-center">
                    <div className="spinner-border mb-4 mt-4" role="status" style={{ width: '200px', height: '200px' }}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div>
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/">Hem</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Nuvarande Lån</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <h1>Ditt lån</h1>
                        <Card className="w-80" >
                            <CardHeader>
                                <CardTitle tag="h4">Nuvarande lån</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <CardGroup>
                                    <Card>
                                        <CardHeader className="text-center">
                                            <CardTitle className="text-center" tag="strong">Ursprungligt lån</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                                <CardText className="text-center">{account.loan.principal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {account.currency.split('/').pop()}</CardText>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardHeader className="text-center">
                                            <CardTitle className="text-center" tag="strong">Kvarstående lån</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                                <CardText className="text-center">{(-account.balance.current).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {account.currency.split('/').pop()}</CardText>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardHeader className="text-center">
                                            <CardTitle className="text-center" tag="strong">Tillstånd</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <CardText className="text-center">{translateState(account.loan.status)}</CardText>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </CardBody>
                        </Card>
                        <Row style={{ width: "80%" }} className="mt-4">
                                <Col xs="12" md="6" className="mb-3 mb-md-0">
                                    <RepaymentModal isOpen={modalIsOpen} toggle={toggleRepaymentModal} ocr={account.searchName} />
                            </Col>
                            <Col xs="12" md="6" className="mb-3 mb-md-0">
                                <LoanFreeMonthModal isOpen={modal2IsOpen} toggle={toggleRepaymentPostponementModal} />
                            </Col>
                        </Row>

                        {/*{loanRepayments.length > 0 ?*/}
                        {/*    <div>*/}
                        {/*        <h3 className="mt-4 text-center">Aktuella låneinbetalningar</h3>*/}
                        {/*        <p className="mt-2">Det finns aktuella låneinbetalningar skapade av Mina Sidor!</p>*/}
                        {/*    </div>*/}
                        {/*    :*/}
                        {/*    <div>*/}
                        {/*        <h3 className="mt-4 text-center">Aktuella låneinbetalningar</h3>*/}
                        {/*        <p className="mt-2">Det finns inga aktuella låneinbetalningar skapade av Mina Sidor!</p>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {loanReminders.length > 0 ?
                            <h3 className="mt-4">Påminnelser</h3>
                            : <h3 className="mt-4">Inga påminnelser</h3>
                        }
                        {loanReminders.map((reminder, index) => (
                            <Card key={index} className="w-100 mt-2">
                                <CardHeader className={reminder.actualRepaymentDate == null ? 'd-flex justify-content-between bg-warning' : 'd-flex justify-content-between bg-success text-white'} >
                                    <CardTitle tag="strong">{reminder.actualRepaymentDate == null ? "Obetald påminnelse" : "Betald påminnelse"}</CardTitle>
                                    <CardTitle>{reminder.date}</CardTitle>
                                </CardHeader>
                                <CardBody className="d-flex flex-row justify-content-between">
                                    <Table className="w-50">
                                        <tbody>
                                            <tr>
                                                <td><strong>Summa</strong></td>
                                                <td className="text-end">{(reminder.amount + reminder.feeAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {account.currency.split('/').pop()}</td>
                                            </tr>
                                            <tr>
                                                <td><span>Påminnelse nummer {reminder.number}</span></td>
                                                <td></td>
                                                {/*<td className="text-end">{reminder.feeAmount.toFixed(2)} {account.currency.split('/').pop()}</td>*/}
                                            </tr>
                                            <tr>
                                                <td><span>{reminder.printed && reminder.distributionMethod === "P" ? "Faktura utskickad via post" : "Distribuerats annorlunda"}</span></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Button color="primary" style={{ width: "10rem" }} onClick={() => { downloadLoanReminder(reminder.attachments) }} ><FontAwesomeIcon icon={faDownload} /></Button>
                                </CardBody>
                            </Card>
                        ))}


                        <div className="d-flex flex-column align-items-center" style={{ width: "80%" }}>
                            <h3 className="mt-4">Transaktioner</h3>
                            <Table striped className="w-100">
                                <thead>
                                    <tr>
                                        <th>Datum</th>
                                        <th>Beskrivning</th>
                                        <th className="text-end">Summa ({account.currency.split('/').pop()})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(paymentPlan).length !== 0 ?
                                        <tr>
                                            <td><FontAwesomeIcon className="me-2" icon={faClock} /> {paymentPlan.nextDueDate}</td>
                                            <td>Kommande inbetalning</td>
                                            <td className="text-end">{paymentPlan.repaymentAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

                                        </tr> : <tr></tr>
                                    }
                                    {transactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td><FontAwesomeIcon className="me-2" icon={faCheck} /> {transaction.valueDate}</td>
                                            <td>{transaction.description}</td>
                                            <td className="text-end" style={{ color: transaction.amount < 0 ? "red" : "green" }}>{Math.abs(transaction.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withCookies(CurrentLoan);
