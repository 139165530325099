import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faUser, faSignOut } from '@fortawesome/free-solid-svg-icons'
//import { faHistory } from '@fortawesome/free-solid-svg-icons'


const Home = (props) => {
    const { cookies } = props;
    const navigate = useNavigate();

    const [authToken, setAuthToken] = useState(cookies.get('gc_token'));
    const [isCookiePresent, setIsCookiePresent] = useState(false);
    const [isCustomer, setIsCustomer] = useState(true);

    useEffect(() => {
        // Check if the specific cookie is present
        if (cookies.get('gc_token')) {
            setIsCookiePresent(true);
            const fetchAccount = async () => {
                try {
                    const response = await fetch('/goodcash/account', { credentials: "include" });
                    if (response.status !== 200) {
                        if (response.status === 401) {
                            navigate('/logout');
                        }
                        else if (response.status === 404) {
                            // This is not a GoodCash customer.
                            setIsCustomer(false);
                        }
                    }
                    else {
                        await response.json();
                        setIsCustomer(true);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    //setIsLoading(false);
                }
            }
            fetchAccount();
        }
        else {
            setIsCookiePresent(false);
        }
    }, [authToken, cookies]);


    // Update token with every render when its value has changed.
    const presenceCookie = cookies.get("gc_token");
    if (authToken !== presenceCookie) {
        setAuthToken(presenceCookie);
    }

    return (
        isCookiePresent ?
            (isCustomer ?
                <div>
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem active>Hem</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <h1>Mina sidor - {cookies.get('gc_name')}</h1>
                        <br></br>
                        <Row style={{ width: "80%" }}>
                            <Col xs="12" md="6" className="mb-3 mb-md-0">
                                <Button color="primary" block onClick={() => navigate('/current')} style={{ height: "20rem" }}>
                                    <FontAwesomeIcon icon={faBank} />
                                    <p>Hantera aktuellt lån</p>
                                </Button>
                            </Col>
                            {/*<Col xs="12" md="4" className="mb-3 mb-md-0">*/}
                            {/*    <Button color="primary" block onClick={() => navigate('/history')} style={{ height: "20rem" }}>*/}
                            {/*        <FontAwesomeIcon icon={faHistory} />*/}
                            {/*        <p>Se tidigare lån</p>*/}
                            {/*    </Button>*/}
                            {/*</Col>*/}
                            <Col xs="12" md="6">
                                <Button color="primary" block onClick={() => navigate('/contact')} style={{ height: "20rem" }}>
                                    <FontAwesomeIcon icon={faUser} />
                                    <p>Ändra kontaktuppgifter</p>
                                </Button>
                            </Col>
                            <hr className="mt-3"></hr>
                            <Col xs="12" md="12">
                                <Button color="primary" className="pt-3" block onClick={() => navigate('/logout')}>
                                    <FontAwesomeIcon icon={faSignOut} />
                                    <p>Logga ut</p>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                :
                <div>
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem active>Hem</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <h1>Mina sidor - {cookies.get('gc_name')}</h1>
                        <br></br>
                        <Row style={{ width: "80%" }}>
                            <Col xs="12" className="mb-3 mb-md-0">
                                <h4 className="text-center">
                                    Hej, det verkar inte som att du är kund hos GoodCash för närvarande.
                                    Om detta inte stämmer med din uppfattning, kontakta oss på <a href="mailto:info@goodcash.se">info@goodcash.se</a>.
                                </h4>
                            </Col>
                            
                            <hr className="mt-3"></hr>
                            <Col xs="12" md="12">
                                <Button color="primary" className="pt-3" block onClick={() => navigate('/logout')}>
                                    <FontAwesomeIcon icon={faSignOut} />
                                    <p>Logga ut</p>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                )
            :
            <div className="d-flex flex-column align-items-center">
                <h1>Mina Sidor</h1>
                <div style={{ maxWidth: "800px" }}>
                    <p className="pt-4">På GoodCash.se - Mina Sidor kan du enkelt hantera ditt lån. Genom att logga in med BankID får du tillgång till en mängd användbara funktioner:</p>
                    <ol>
                        <li><strong>Lånestatus:</strong> Här kan du följa din lånesituation och hålla koll på återbetalningsschemat. Det är smidigt och överskådligt.</li>
                        <li><strong>Räntefri månad:</strong> Behöver du andrum? Du har möjlighet att begära en räntefri månad för att lätta på ekonomin när det behövs.</li>
                        <li><strong>Betalningspåminnelser:</strong> Glöm aldrig en betalning igen! På Mina Sidor kan du enkelt se nästa gång det är dags att betala och eventuellt hämta betalningspåminnelser.</li>
                        <li><strong>Återbetalning:</strong> Om du vill lösa ditt lån i förtid eller göra extra betalningar, kan du enkelt begära återbetalning direkt från ditt konto.</li>
                    </ol>
                    <p>GoodCash.se - Mina Sidor ger dig kontroll över ditt lån och gör det enkelt att anpassa det efter dina behov. Logga in med BankID och upptäck alla fördelarna redan idag.</p>
                </div>

                <Link to="/login">
                    <Button color="primary" className="p-4 mt-4">
                        Logga in
                        <img src="BankID_logo_white.svg" height="50px" alt="BankID Logo" />
                    </Button>
                </Link>
            </div>
    );
};

export default withCookies(Home);
