import React, { useState, useEffect } from 'react';
import { useQrious } from 'react-qrious';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import './Login.css'; // Import a separate CSS file for styling

const Login = () => {
    const [data, setData] = useState({});
    const [collectData, setCollectData] = useState({});
    const [qr, setQr] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [remoteOrLocalSelected, setRemoteOrLocalSelected] = useState(false);
    const [useRemoteDevice, setUseRemoteDevice] = useState(false);

    const [dataUrl] = useQrious({ value: qr })
    const navigate = useNavigate();

    const rpa = {
        "hintcode-qr-outstandingTransaction": "Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden ovan.",
        "hintcode-desktop-outstandingTransaction": "Försöker starta BankID Säkerhetsprogram",
        "hintcode-mobile-outstandingTransaction": "Klicka på knappen nedan för att starta BankID-appen.",

        "hintcode-qr-started": "Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden ovan.",
        "hintcode-desktop-started": "Försöker starta BankID Säkerhetsprogram",
        "hintcode-mobile-started": "Klicka på knappen nedan för att starta BankID-appen.",

        "hintcode-userSign": "Skriv din säkerhetskod i BankID- appen och välj Identifiera.",
        "hintcode-desktop-userSign": "Skriv din säkerhetskod i BankID Säkerhetsprogram och välj Identifiera eller Skriv under.",

        "hintcode-unknown": "Något gick fel. Försök igen.",
        "hintcode-userCancel": "Åtgärden avbröts.",

        "hintcode-startFailed": "BankID-appen verkar inte finnas i din dator eller telefon. Installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.",
        "hintcode-qr-startFailed": "Skanningen misslyckades. Starta BankID-appen och skanna QR koden. Kontrollera att appen är uppdaterad. Om du inte har appen måste du installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.",

        "hintcode-expiredTransaction": "BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa det hos din bank. Försök sedan igen.",
        "hintcode-certificateErr": "Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din internetbank.",
        "hintcode-alreadyInProgress": "En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.",
        "hintcode-requestTimeout": "Något gick fel. Försök igen.",
        "hintcode-maintenance": "Något gick fel. Försök igen.",
        "hintcode-internalError": "Något gick fel. Försök igen."
    }


    const translate = (key, altkey) => {
        if (key in rpa) {
            return rpa[key];
        }
        else if (altkey in rpa) {
            return rpa[altkey];
        }
        else {
            return "";
        }
    }


    useEffect(() => {
        const startT = new Date();
        let t = null;
        let t2 = null;

        const clearAllData = async () => {
            setData({});
            setCollectData({});
            setQr('');
            clearInterval(t);
            clearInterval(t2);
        }

        const generateQr = async (orderRef) => {
            const qrResponse = await fetch('bankid/qrcode/' + orderRef);
            const qrResult = await qrResponse.json();
            setQr(qrResult.qrCode);
        }

        const collect = async (orderRef) => {
            // See if the authentication has gone on too long.
            if (startT == null) {
                return
            }
            if ((new Date() - startT) > 120000) {
                clearInterval(t);
                clearInterval(t2);
                return
            }

            // Otherwise, make the collect call.
            let collectResult = null;
            try {
                const collectResponse = await fetch('bankid/collect/' + orderRef);
                collectResult = await collectResponse.json();
                setCollectData(collectResult);
            }
            catch (error) {
                console.error('Error collecting BankID status:', error);
                clearAllData();
                navigate('/');
                // TODO: State error and redirect.
            }

            // Handle the result of the collect call.
            if (collectResult.status === "complete") {
                console.log("Completed authorization!");
                clearAllData();
                navigate('/');
            }
            else if (collectResult.status === "failed") {
                console.log("Failed authorization!");
                clearAllData();
                navigate('/');
                // TODO: Show error message and redirect.
            }
            else if (collectResult.status === "pending") {
                if (collectResult.hintCode === "userSign") {
                    setQr('');
                    clearInterval(t);
                }
            }
            else {
                console.log("Something went wrong...")
            }
        }

        const fetchData = async () => {
            try {
                const response = await fetch('bankid/auth');
                const result = await response.json();
                setData(result);

                const qrResponse = await fetch('bankid/qrcode/' + result.orderRef);
                const qrResult = await qrResponse.json();
                setQr(qrResult.qrCode);

                setIsLoading(false);

                // Fetch new QR code content every second.
                t = setInterval(() => generateQr(result.orderRef), 2000);
                // Perform collect call every other second.
                t2 = setInterval(() => collect(result.orderRef), 2000);
            } catch (error) {
                console.error('Error fetching data:', error);
                //setIsLoading(false);
            }
        };

        fetchData();

        // Clean up intervals when the component is unmounted
        return () => {
            clearInterval(t);
            clearInterval(t2);
        };
    }, []);

    return (
        <div className="login-container">
            {isLoading ? (
                <p>Initierar...</p>
            ) : (
                <div className="d-flex flex-column align-items-center">
                    <h1>Logga in med BankID</h1>
                    {
                        remoteOrLocalSelected ?
                            <h3>{translate('identify-with-bankid', '')}</h3>

                            :
                            <>
                                <Button color="primary" className="mb-2 p-3 w-100 text-center" tag={Link} to={`bankid:///?autostarttoken=${data.autoStartToken}&redirect=null`} onClick={() => { setRemoteOrLocalSelected(true); setUseRemoteDevice(false); }}>BankID på den här enheten</Button>
                                <Button color="primary" className="w-100 p-3 text-center" onClick={() => { setRemoteOrLocalSelected(true); setUseRemoteDevice(true); }}>Mobilt BankID på en annan enhet</Button>
                            </>

                    }
                    {
                        remoteOrLocalSelected && useRemoteDevice && qr ?
                            <>
                                <img width="200" height="200" src={dataUrl} alt="The QR Code" className="mb-4 mt-4" />
                                <p>{translate(`hintcode-qr-${collectData.hintCode}`, `hintcode-${collectData.hintCode}`)}</p>
                            </>


                            : remoteOrLocalSelected ?
                                <>
                                    <div className="spinner-border mb-4 mt-4" role="status" style={{ width: '200px', height: '200px' }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>{translate(`hintcode-qr-${collectData.hintCode}`, `hintcode-${collectData.hintCode}`)}</p>
                                </>
                                :
                                <></>
                    }
                </div>
            )}
        </div>
    );
};

export default Login;