import Home from "./components/Home";
import Login from "./components/Login";
import Logout from "./components/Logout";
import CurrentLoan from "./components/CurrentLoan";
import HistoricalLoans from "./components/HistoricalLoans";
import ContactDetails from "./components/ContactDetails";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
        path: '/current',
        element: <CurrentLoan />
    },
    {
        path: '/history',
        element: <HistoricalLoans />
    },
    {
        path: '/contact',
        element: <ContactDetails />
    },

];

export default AppRoutes;
