import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';


const Logout = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        const { cookies } = props;

        // Check if the specific cookie is present
        if (cookies.get('gc_token')) {
            // Delete the cookie
            cookies.remove('gc_token');
        }
        if (cookies.get('gc_name')) {
            // Delete the cookie
            cookies.remove('gc_name');
        }
        navigate('/');
    }, [navigate, props]);

    return (
        <div>
            <p>Loggar ut...</p>
        </div>
    );
};

export default withCookies(Logout);