import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';

function ContactDetails() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailButtonDisabled, setEmailButtonDisabled] = useState(false);
    const [phoneNumberButtonDisabled, setPhoneNumberButtonDisabled] = useState(false);

    useEffect(() => {
        // Fetch initial data from the backend and update state
        // You can replace the following with actual data fetching code
        const getPhoneNumbers = async () => {
            const response = await fetch('/goodcash/getphones', { credentials: "include" });
            let result;
            if (response.status !== 200) {
                result = { error: response.statusText };
                if (response.status === 401) {
                    navigate('/');
                }
            }
            else {
                let phones = await response.json();
                let cellPhones = phones.filter(obj => obj.type === "C1")
                if (cellPhones.length === 0) {
                    let otherPhones = phones.filter(obj => obj.type === "P1")
                    if (otherPhones.length === 0) {
                        handlePhoneNumberChange({ target: { value: "" } });
                    }
                    else {
                        handlePhoneNumberChange({ target: { value: otherPhones[0].number } });
                    }
                }
                else {
                    handlePhoneNumberChange({ target: { value: cellPhones[0].number } });
                }

                console.log('Phone Numbers Fetched:', phones);
            }
        };
        const getEmail = async () => {
            const response = await fetch('/goodcash/getemail', { credentials: "include" });
            let result;
            if (response.status !== 200) {
                result = { error: response.statusText };
                if (response.status === 401) {
                    navigate('/');
                }
            }
            else {
                let email = await response.text();
                email = email.replaceAll('"', '')
                setEmail(email);
                console.log('Email Fetched:', email);
            }
        };


        getPhoneNumbers();
        getEmail();
    }, []);

    const isValidEmail = (email) => {
        // Regular expression for a simple email address validation
        const regex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

        // Test the input email against the regular expression
        return regex.test(email);
    }

    const handleEmailChange = (e) => {
        // Validate and set the phone number input
        const inputValue = e.target.value;
        if (isValidEmail(inputValue)) {
            setEmailButtonDisabled(false);
        }
        else {
            setEmailButtonDisabled(true);
        }
        setEmail(inputValue);
    };

    const handleEmailSubmit = async () => {
        // Disable the email button and send the email data to the backend service
        setEmailButtonDisabled(true);

        try {
            const response = await fetch(
                '/goodcash/changeemail',
                {
                    method: 'POST',
                    credentials: "include",
                    body: JSON.stringify({ Email: email }),
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            let result;
            if (response.status !== 200) {
                result = { error: response.statusText };
                if (response.status === 401) {
                    navigate('/');
                }
            }
            else {
                result = await response.text;
                console.log('Email Change:', result);
                setEmailButtonDisabled(false);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            //setIsLoading(false);
        }
    };

    const isValidPhoneNumber = (phoneNumber) => {
        // Define regular expressions for the two formats
        const regex1 = /^07\d{1}\d{7}$/; // Matches "07YXXXXXXX"
        const regex2 = /^\+467\d{8}$/;   // Matches "+467YXXXXXXX"

        // Test the input phoneNumber against both regular expressions
        return regex1.test(phoneNumber) || regex2.test(phoneNumber);
    }

    const handlePhoneNumberChange = (e) => {
        // Validate and set the phone number input
        const inputValue = e.target.value;
        const validatedPhoneNumber = inputValue.replace(/[^0-9+]/g, '');
        if (isValidPhoneNumber(validatedPhoneNumber)) {
            setPhoneNumberButtonDisabled(false);
        }
        else {
            setPhoneNumberButtonDisabled(true);
        }
        setPhoneNumber(validatedPhoneNumber);
    };

    const handlePhoneNumberSubmit = async () => {
        // Disable the phone number button and send the phone number data to the backend service
        // Replace with actual API call
        setPhoneNumberButtonDisabled(true);

        try {
            const response = await fetch(
                '/goodcash/addphone',
                {
                    method: 'POST',
                    credentials: "include",
                    body: JSON.stringify({ PhoneNbr: phoneNumber }),
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            let result;
            if (response.status !== 200) {
                result = { error: response.statusText };
                if (response.status === 401) {
                    navigate('/');
                }
            }
            else {
                result = await response.text;
                console.log('Phone Number Change:', result);
                setPhoneNumberButtonDisabled(false);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            //setIsLoading(false);
        }
    };

    return (
        <div>
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Hem</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Ändra kontaktuppgifter</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div className="d-flex flex-column align-items-center">
                <h1>Ändra kontaktuppgifter</h1>

                <Card className="w-80 mt-2">
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="email">Epost</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Skriv in din epostadress"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                <Button color="primary" className="mt-2" onClick={handleEmailSubmit} disabled={emailButtonDisabled} style={{ fontSize: "1em" }}>
                                    Ändra epostadress
                                </Button>
                            </FormGroup>

                            <FormGroup>
                                <Label for="phoneNumber">Telefonnummer
                                    <span
                                        id="phoneNumberTooltip"
                                        className="info-icon ps-1"
                                        data-toggle="tooltip"
                                        title="Bara plustecken och siffror är tillåtna. Format som accepteras är 07YXXXXXXX eller +467YXXXXXXX."
                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                </Label>
                                <Input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    placeholder="Fyll i telefonnummer"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                                <Button color="primary" className="mt-2" onClick={handlePhoneNumberSubmit} disabled={phoneNumberButtonDisabled} style={{ fontSize: "1em" }}>
                                    Ändra telefonnummer
                                </Button>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default ContactDetails;
