import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

function NavMenu(props) {
    return (
        <header>
            <Navbar className="navbar-expand-md navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand className="mx-auto" tag={Link} to="/"><img src="/logo.png" alt="GoodCash.se Logo" /></NavbarBrand>
            </Navbar>
        </header>
    );
}

export default NavMenu;
