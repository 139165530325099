import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { withRouter } from './ComponentWithRouterProps';


class LoanFreeMonthModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            requestPushed: false,
            isLoading: false,
            requestSent: false
        };

        this.toggle = this.toggle.bind(this);
        this.confirmRequest = this.confirmRequest.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    async confirmRequest() {
        let result = {};
        this.setState({
            requestPushed: !this.state.requestPushed,
            isLoading: true
        });
        try {
            const response = await fetch('/goodcash/LoanFreeMonth', {method: "POST", credentials: "include" });
            if (response.status !== 200) {
                result = { error: response.statusText };
                if (response.status === 401) {
                    this.props.navigate('/logout');
                }
                else {
                    console.log('Error requesting loan free month:', result);
                }
            }
            else {
                result = await response.text();
                this.setState({
                    requestSent: true
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        this.setState({
            isLoading: false,
        });
    }

    render() {
        return (
            <div>
                <Button block color="primary" className="w-50" onClick={this.toggle}>Ansök om återbetalningsfri månad</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Återbetalningsfri månad</ModalHeader>
                    {this.state.isLoading ? 
                        <ModalBody>
                            <div className="d-flex flex-column align-items-center">
                                <div className="spinner-border mb-4 mt-4" role="status" style={{ width: '150px', height: '150px' }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </ModalBody>
                        : this.state.requestSent ?
                            <ModalBody>
                                <p>Din ansökan om återbetalningsfri månad har skickats till GoodCash.se och kommer behandlas inom kort.</p>
                            </ModalBody>
                        :
                        <ModalBody>
                            <p>För att erhålla en betalningsfri månad måste du uppfylla följande villkor: </p>
                            <ul>
                                <li>Minst 4 månatliga amorteringar av ditt lån måste ha skett och där ingen får ha hamnat på påminnelse.</li>
                                <li>Max en betalningsfri månad per kalenderår.</li>
                            </ul>
                            <p>Uppfylls villkoren och ansökan är gjord innan den 13:e kalenderdagen så blir innevarande månad betalningsfri.
                                Görs ansökan efter den 13:e blir påföljande månad betalningsfri.
                            </p>  
                        </ModalBody>
                    }
                    <ModalFooter>
                        <Button color="primary" disabled={ this.state.requestPushed } onClick={this.confirmRequest}>Skicka ansökan</Button>{' '}
                        <Button color="secondary" onClick={ this.toggle }>Återgå</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withRouter(LoanFreeMonthModal);