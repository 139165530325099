import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { withRouter } from './ComponentWithRouterProps';

class RepaymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            isLoading: true,
            earlyMaturity: {},
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if (!this.state.modal) {
            this.setState({
                isLoading: true
            });
            this.loadEarlyMaturity();
        }    
        this.setState({
            modal: !this.state.modal,
        });
    }

    async loadEarlyMaturity() {
        let result = {};
        try {
            const response = await fetch('/goodcash/EarlyMaturity', { credentials: "include" });
            if (response.status !== 200) {
                result = { error: response.statusText };
                if (response.status === 401) {
                    this.props.navigate('/logout');
                }
                else {
                    console.log('Error fetching early maturity information :', result);
                }
            }
            else {
                result = await response.json();
            }       
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({
                modal: !this.state.modal,
                isLoading: false
            });
            return;
        }
        this.setState({
            earlyMaturity: result,
            isLoading: false
        });
    }

    render() {
        return (
            <div>
                <Button block color="primary" className="w-50" onClick={this.toggle}>Återbetala lån</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={this.toggle}>Återbetala lån</ModalHeader>
                    {this.state.isLoading ? (
                        <ModalBody>
                            <div className="d-flex flex-column align-items-center">
                                <div className="spinner-border mb-4 mt-4" role="status" style={{ width: '150px', height: '150px' }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </ModalBody>
                    ) : (
                        <>
                            <ModalBody>
                                    <p>Om du vill återbetala ditt lån kan du göra en inbetalning för att avsluta det så här:</p>
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <td><strong>Summa att betala:</strong></td>
                                                <td style={{ "textAlign": "right" }} >{this.state.earlyMaturity.AmountToPay.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} SEK</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Ange vid betalning OCR-nummer:</strong></td>
                                                <td style={{ "textAlign": "right" }} >{this.props.ocr}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Bankgiro för betalning:</strong></td>
                                                <td style={{ "textAlign": "right" }} >357-5206</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>Summan att betala är bedömd två arbetsdagar framåt, vilket är då vi får in pengarna, så det kan finnas en differens mot det som anges på sidan "Ditt lån".</p>
                                    
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.toggle}>Tillbaka</Button>
                            </ModalFooter>
                        </>
                        )}
                </Modal>
            </div>
        );
    }
}
/*
<>
    <ModalBody>
            <p>Återbetalning av lån är begärd.</p>
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <td><strong>Belopp:</strong></td>
                        <td>{this.state.earlyMaturityRepayment.Amount.toFixed(2)} SEK</td>
                    </tr>
                    <tr>
                        <td><strong>OCR-nummer:</strong></td>
                        <td>{this.props.ocr }</td>
                    </tr>
                    <tr>
                        <td><strong>Bankgiro:</strong></td>
                        <td>357-5206</td>
                    </tr>
                    <tr>
                        <td><strong>Återbetalningsdatum:</strong></td>
                        <td>{this.state.earlyMaturityRepayment.RepaymentDate}</td>
                    </tr>
                </tbody>
            </table>
    </ModalBody>
    <ModalFooter>
        <Button color="primary" onClick={this.toggle}>Återgå</Button>
    </ModalFooter>
</>
*/

export default withRouter(RepaymentModal);